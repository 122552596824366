import axios from 'axios'
import qs from 'qs'
import { ElMessage, ElLoading } from 'element-plus'
import * as XLSX from 'xlsx' // 导出excel插件
import { getLodop } from './LodopFuncs'
// import * as FileSaver from 'file-saver' // 导出各种格式文件

import router from '@/router/index'
// import { useStore } from '@/store/index'
// const store = useStore()
//qs.stringify()是将对象 序列化成URL的形式，以&进行拼接
//  let protocol = window.location.protocol; //协议
//  let host = window.location.host; //主机
//  axios.defaults.baseURL = protocol + "//" + host;
// axios.defaults.baseURL = 'http://www.weituanxianmi.com'
// 工具函数
/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

let loadingInstance = null // loadding动画变量
// axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

const service = axios.create({
  // baseURL: '/api', // 公共接口
  timeout: 10000,  // 最大请求时间设置
  headers: {
    'token': 'wangchun',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

//请求拦截器 请求之前的操作
service.interceptors.request.use(
  async config => {
    // 如果有需要在这里开启请求时的loadding动画效果
    loadingInstance = ElLoading.service({
      lock: true,
      text: '请求访问中…',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })

    // config.headers.Authorization = 'wangchun' // 添加token，需要结合自己的实际情况添加
    // 每次发送请求之前判断vuex中是否存在token
    // config.headers.token = sessionStorage.getItem('token')
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器 响应之后的操作
service.interceptors.response.use(
  response => {
    // 在这里关闭请求时的loadding动画效果
    loadingInstance.close()

    if (response.status === 200) {
      return Promise.resolve(response); //进行中        
    } else {
      ElMessage.error('访问失败！')
      return Promise.reject(response); //失败
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    // 在这里关闭请求时的loadding动画效果
    loadingInstance.close()

    if (error && error.response) {
      switch (error.response.status) {
        // 401: 未登录                
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作
        case 401:
          error.message = '请重新登录[401]'
          $openFrame('/')
          break
        // 403 token过期
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          error.message = '拒绝访问[403]'
          // sessionStorage.clear()
          $openFrame('/')
          break
        case 404:
          error.message = '请求出错[404]'
          break;
        case 500:
          error.message = '服务器错误[500]'
          break;
        // 其他错误，直接抛出错误提示
        default:
          error.message = '连接错误[' + error.response.status + ']'
      }

      ElMessage.error(error.message)
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        ElMessage.error('服务器响应超时：' + error.message)
      }
    }
    return Promise.reject(error.response);
  }
)

/** 
* get方法，对应get请求 
* @param {String} url [请求的url地址] 
* @param {Object} params [请求时携带的参数] 
*/
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    service.get(url, { params: params }).then(res => { resolve(res.data) }).catch(err => { reject(err) })
  })
}

/** 
* post方法，对应post请求 
* @param {String} url [请求的url地址] 
* @param {Object} params [请求时携带的参数] 
*/
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    //是将对象 序列化成URL的形式，以&进行拼接
    service.post(url, qs.stringify(params)).then(res => { resolve(res.data); }).catch(err => { reject(err) })
  })
}

/** 
* 上传图片、文件 
* @param {String} url [请求的url地址] 
* @param {Object} data [请求时携带的参数] 
*/
const $upLoadFile = (url, formData) => {
  return new Promise((resolve, reject) => {

    // 如果有需要在这里开启请求时的loadding动画效果
    loadingInstance = ElLoading.service({
      lock: true,
      text: '文件上传中…',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })

    axios.post(url, formData, {
      headers: {
        'token': 'wangchun',
        // 表示上传的是文件,而不是普通的表单数据
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (res) {
      // 在这里关闭请求时的loadding动画效果
      loadingInstance.close()
      resolve(res.data)
    }).catch(function (err) {
      // 在这里关闭请求时的loadding动画效果
      loadingInstance.close()
      reject(err)
    })
  })
}

/** 
* 导入导出专用接口
* @param {String} url [请求的url地址] 
* @param {Object} data [请求时携带的参数] 
*/
const $importExport = (url, params) => {
  return new Promise((resolve, reject) => {
    // 如果有需要在这里开启请求时的loadding动画效果
    loadingInstance = ElLoading.service({
      lock: true,
      text: '请求中，请稍后',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    axios.post(url, qs.stringify(params)).then(res => {
      // 在这里关闭请求时的loadding动画效果
      loadingInstance.close()
      resolve(res.data)
    }).catch(function (err) {
      // 在这里关闭请求时的loadding动画效果
      loadingInstance.close()
      reject(err)
    })
  })
}

/**
* 获取浏览器地址 端口和域名
*/
const $getURL = () => {
  const protocol = window.location.protocol // http:|| https:
  const hostname = window.location.hostname // 域名
  const port = window.location.port // 端口
  let Url = protocol + '//' + hostname + ':' + port

  if (port === '80' && port === '443') {
    Url = protocol + '//' + hostname
  }
  return Url
}

// 跳转页面
const $openFrame = (path, query) => {
  try {
    if (query) {
      // path 不能用params传参 因为params需要name
      router.push({ path: path, query: query })
    } else {
      router.push({ path: path })
    }
  }
  catch (err) { ElMessage.error('无效路由!'); return false }
}

// 跳转页面关闭返回
const $replaceFrame = (path, query) => {
  try {
    if (query) {
      // path 不能用params传参 因为params需要name
      router.push({ path: path, query: query, replace: true })
    } else {
      router.push({ path: path, replace: true })
    }

  }
  catch (err) { ElMessage.error('无效路由!'); return false }
}

// 获取页面传参
const $getQuery = (pathName) => {
  let query_val = router.currentRoute.value.query
  return query_val[pathName]
}

/**
 * 图片转换为 base64
 */
const $getBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader()
    let imgResult = ''
    reader.readAsDataURL(file)
    reader.onload = function () {
      imgResult = reader.result
    }
    reader.onerror = function (error) {
      reject(error)
    }
    reader.onloadend = function () {
      resolve(imgResult)
    }
  })
}

/**
 * 当前时间  2020年07月01日 10:35:54 星期三
 */
const $writeCurrentDate = () => {
  let now = new Date()
  let year = now.getFullYear() // 得到年份
  let month = (now.getMonth() + 1)// 得到月份
  let date = now.getDate()// 得到日期
  let day = now.getDay()// 得到周几
  let hour = now.getHours()// 得到小时
  let minu = now.getMinutes()// 得到分钟
  let sec = now.getSeconds()// 得到秒
  let MS = now.getMilliseconds()// 获取毫秒
  let week
  if (month < 10) month = '0' + month
  if (date < 10) date = '0' + date
  if (hour < 10) hour = '0' + hour
  if (minu < 10) minu = '0' + minu
  if (sec < 10) sec = '0' + sec
  if (MS < 100) MS = '0' + MS
  let arrWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  week = arrWeek[day]
  let time = ''
  time = year + '年' + month + '月' + date + '日' + ' ' + hour + ':' + minu + ':' + sec + ' ' + week
  // 设置得到当前日期的函数的执行间隔时间，每1000毫秒刷新一次。
  return time
}

/**********************************************************************************************************************************
 * 获取当前时间（年月日时分秒）格式 
 * $getCurrentDate('YY') // 2022
 * $getCurrentDate('YY-MM')	// 2022-06
 * $getCurrentDate('YY-MM-DD')	// 2022-06-02
 * $getCurrentDate('YY-MM-DD hh:mm:ss')	// 2022-06-02 10:02:23
 * $getCurrentDate('星期W')	// 星期四
 **********************************************************************************************************************************/
const $getCurrentDate = (fmt) => {
  const date = new Date()
  const o = {
    'Y+': date.getFullYear(),
    'M+': date.getMonth() + 1, // 月
    'D+': date.getDate(), // 日
    'h+': date.getHours(), // 时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    W: date.getDay() // 周
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, () => {
        if (k === 'W') {
          // 星期几
          const week = ['日', '一', '二', '三', '四', '五', '六']
          return week[o[k]]
        } else if (k === 'Y+' || RegExp.$1.length === 1) {
          // 年份 or 小于10不加0
          return o[k]
        } else {
          return ('00' + o[k]).substr(('' + o[k]).length) // 小于10补位0
        }
      })
    }
  }
  return fmt
}

/**
* 只能输入数字并且最多允许小数点两位
*/
const $checkPrice = (value) => {
  value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
  value = value.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (value.indexOf('.') < 0 && value !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    value = parseFloat(value)
  }
  return value
}
/**
* 验证是否整数
*/
const $isInteger = (rule, value, callback) => {
  if (value) {
    const re = /^[0-9]*[1-9][0-9]*$/
    if (!re.test(value)) {
      callback(new Error('请输入整数'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 本地分页大小返回  val 返回的值, JsonData 要修改的数组
const $localSizeChang = (val, JsonData) => { JsonData['size'] = val }

// 本地分页页码返回  val 返回的值, JsonData 要修改的数组
const $localPageChang = (val, JsonData) => { JsonData['page'] = val }

// 图片插件传参返回 val 返回的值, jsondata 要修改的数组, txt 要修改的数组的键名
const $imgFileChang = (val, jsondata, txt) => { jsondata[txt] = val ? val : '' }
/** 
*导出excel
*/
// const $exportExcel = (id, name) => {
//   // let dataf = document.getElementById(id)
//   // 导出文件名
//   let fileName = $getCurrentDate('YY-MM-DD') + name

//   // const filename = '批发商数据'
//   // 通过id，获取导出的表格数据
//   const wb = XLSX.utils.table_to_book(document.getElementById(id))

//   const wbout = XLSX.write(wb, {
//     bookType: 'xlsx',
//     bookSST: true,
//     type: 'array'
//   })

//   try {
//     FileSaver.saveAs(
//       new Blob([wbout], {
//         type: 'application/octet-stream'
//       }),
//       fileName + '.xlsx'
//     )
//   } catch (e) {
//     console.log(e)
//   }
//   return wbout
// }

/**
   * 导出 Excel 表格
   * @param {Object} json 服务端发过来的数据
   * @param {String} name 导出Excel文件名字 
   */
const $exportExcel = (json, name) => {
  // 导出文件名称
  let fileName = $getCurrentDate('YY-MM-DD') + name
  const data = XLSX.utils.json_to_sheet(json) //此处tableData.value为表格的数据
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, data) //test-data为自定义的sheet表名
  XLSX.writeFile(wb, fileName + '.xlsx') //test.xlsx为自定义的文件名
}


const $CheckIsInstall = () => {
  var LODOP = getLodop();
  if (LODOP.VERSION) {
    return true
  } else {
    return false
  }
}

// 设置打印小票格式 分拣单
const $lodopFJD = (json) => {
  // 设置内容样式
  let sthtml = '<div style="width: 100%;height: 100%;font-family:\'微软雅黑\'; font-size:10pt">' +
    '<div style="width: 100%; text-align: center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:16pt;"><strong>' + json.NickName + '</strong></div>' +
    '<div style="width: 100%; text-align: center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:14pt;"><strong>' + json.GoodsName + '</strong></div>' +
    '<div style="width: 100%; text-align: center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:14pt;"><strong>' + json.GoodsDetailName + '</strong></div>' +
    '<div style="width: 100%; text-align: center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:14pt;"><strong>分拣：' + json.ActualNumber + json.Unit + '</strong></div>' +
    '<div style="width: 100%; text-align: center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:12pt;">司机：' + json.SiJiName + '</div>' +
    '</div>'

  let LODOP = getLodop()

  if (!LODOP.VERSION) {
    ElMessage.error('Web打印服务CLodop未安装启动,安装完请重新打开浏览器')
    return false
  }

  // 设置打印名称  用pdf打印机时会是导出文件名字
  LODOP.PRINT_INIT('Resee分拣单' + new Date().getTime());
  //  SET_PRINT_PAGESIZE(intOrient, PageWidth,PageHeight,strPageName)
  //  intOrient：打印方向及纸张类型 1---纵向打印，固定纸张；  2---横向打印，固定纸张；   3---纵向打印，宽度固定，高度按打印内容的高度自适应 0---方向不定，由操作者自行选择或按打印机缺省设置
  //  PageWidth： 自定义纸张宽度 PageHeight：自定义纸张高度  缺省长度单位为0.1mm
  //  strPageName：纸张类型名 例如A4 宽或高无效时 trPageName才起作用。
  LODOP.SET_PRINT_PAGESIZE(1, 600, 400, '')
  LODOP.SET_PRINT_MODE("PROGRAM_CONTENT_BYVAR", true);
  LODOP.ADD_PRINT_HTML('0', "0", "100%", "100%", sthtml)
  LODOP.PRINT()
  // LODOP.PREVIEW()  //打印预览 
}

// 处理带T的时间  传入日期//例：2020-10-27T14:36:23
const $timeFormatSeconds = (time) => {
  if (!time) return ''
  var d = new Date(time)
  var year = d.getFullYear()
  var month = d.getMonth() + 1
  var day = d.getDate()
  var hours = d.getHours()
  var min = d.getMinutes()
  var seconds = d.getSeconds()

  if (month < 10) month = '0' + month
  if (day < 10) day = '0' + day
  if (hours < 0) hours = '0' + hours
  if (min < 10) min = '0' + min
  if (seconds < 10) seconds = '0' + seconds

  return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds)
}

// 获取明天日期
const $getNextDay = () => {
  let now = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate();
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  return year + "-" + month + "-" + day
}

/**
   * 按回车键切换下一个input获取焦点
   * @param { event } event 当前对象
   * @param { Number } index 当前对象的index
   * @param { String } name 当前对象的index
   */

const $keydownTo = (event, index, name) => {
  let arrs = document.querySelectorAll('[data-' + name + ']')
  let keyNum = event.keyCode
  // 回车：13；向上：38；向下：40
  switch (keyNum) {
    case 13:
      index + 1 < arrs.length ? arrs[index + 1].focus() : false
      break;
    case 38:
      index > 0 ? arrs[index - 1].focus() : false
      break;
    case 40:
      index + 1 < arrs.length ? arrs[index + 1].focus() : false
      break;
  }
}

export default {
  install: (app) => {
    app.config.globalProperties['$getURL'] = $getURL
    app.config.globalProperties['$get'] = $get
    app.config.globalProperties['$post'] = $post
    app.config.globalProperties['$upLoadFile'] = $upLoadFile
    app.config.globalProperties['$axios'] = axios
    app.config.globalProperties['$getBase64'] = $getBase64
    app.config.globalProperties['$writeCurrentDate'] = $writeCurrentDate
    app.config.globalProperties['$checkPrice'] = $checkPrice
    app.config.globalProperties['$openFrame'] = $openFrame
    app.config.globalProperties['$replaceFrame'] = $replaceFrame
    app.config.globalProperties['$getCurrentDate'] = $getCurrentDate
    app.config.globalProperties['$isInteger'] = $isInteger
    app.config.globalProperties['$exportExcel'] = $exportExcel
    app.config.globalProperties['$lodopFJD'] = $lodopFJD
    app.config.globalProperties['$getQuery'] = $getQuery
    app.config.globalProperties['$localSizeChang'] = $localSizeChang
    app.config.globalProperties['$localPageChang'] = $localPageChang
    app.config.globalProperties['$imgFileChang'] = $imgFileChang
    app.config.globalProperties['$timeFormatSeconds'] = $timeFormatSeconds
    app.config.globalProperties['$getNextDay'] = $getNextDay
    app.config.globalProperties['$CheckIsInstall'] = $CheckIsInstall
    app.config.globalProperties['$keydownTo'] = $keydownTo
    app.config.globalProperties['$importExport'] = $importExport
  }
}