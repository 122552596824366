import { createApp } from 'vue'
import App from './App.vue'

import './assets/css/normalize.css' // 公共样式
import ElementPlus from 'element-plus' // element-ui插件
import 'element-plus/dist/index.css'  // element-ui插件样式
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' // element-ui语言
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // element-ui 图标
// import * as echarts from 'echarts' // 图表插件
import { createPinia } from 'pinia' // 状态管理
import CopyText from '@/components/copyText/copytext.vue' //全局复制文本
import wcImg from '@/components/wcimage/wc_img.vue' //全局图片展示
import router from './router/index' // 路由管理
import Util from './libs/util' // 全局工具函数

// import './assets/css/font.css' // 引入字体

const app = createApp(App)
app.use(createPinia()) // 注册pinia组件 
app.use(router)// 注册router组件 
app.use(ElementPlus, { locale: zhCn })// 注册element-ui组件 vue官方库支持new Vue()
app.use(ElementPlusIconsVue)// 注册element-ui图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}  // 注册element-ui图标方法
// app.config.globalProperties.$echarts = echarts // 注册图表插件组件  不是为了vue写的插件(插件内要处理)不支持Vue.use()加载方式 要用Vue.prototype
app.component('CopyText', CopyText)  //注册全局复制文本
app.component('wcImg', wcImg)  //注册全局图片展示
app.use(Util) // 注册全局工具函数组件
app.mount('#app')