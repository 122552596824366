<template>
  <router-view v-if="$route.path == ('/')"></router-view>
  <template v-else>
    <el-container class="layout-container">
      <layoutLeft :collapse="isCollapse"></layoutLeft>
      <el-container direction="vertical">
        <layoutTop :collapse="isCollapse" @handlechg="handleChang"></layoutTop>
        <layoutTabs></layoutTabs>
        <el-main>
          <router-view v-slot="{ Component, route }">
            <component :is="Component" v-if="!route.meta.keepAlive && isRouterAlive" :key="route.path" />
            <keep-alive :include="includeName" :max="5">
              <component :is="Component" v-if="route.meta.keepAlive && isRouterAlive" :key="route.path" />
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </template>
</template>

<script setup>
/** 引入 */
import { onBeforeMount, getCurrentInstance, ref, toRaw, provide, onUpdated, onUnmounted, nextTick, computed } from 'vue'
import layoutLeft from '@/components/layout/left.vue'
import layoutTop from '@/components/layout/top.vue'
import layoutTabs from '@/components/layout/tabs.vue'
import { useStore } from '@/store/index'
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()
const store = useStore()
const isCollapse = ref(false)
const isRouterAlive = ref(true)
const includeName = computed(() => store.includePaths);
// 刷新方法
const reload = () => {
  isRouterAlive.value = false
  if (proxy.$route.meta.keepAlive) {
    // 先清除缓存
    store.includePaths = store.includePaths.filter(item => item !== proxy.$route.path)
  }
  nextTick(() => {
    isRouterAlive.value = true
    // 加载后再添加缓存
    if (proxy.$route.meta.keepAlive) {
      if (!store.includePaths.includes(proxy.$route['path'])) {
        store.includePaths.push(proxy.$route['path'])
      }
    }
  })
}

// 注入刷新组件
provide('reload', reload)

/**
 * 组件被挂载前
 */
onBeforeMount(() => {
  store['userid'] = localStorage.getItem('userid') || ''
  const isUserid = localStorage.getItem('userid')
  const isPath = isUserid && window.location.pathname === '/'
  // 如果userid不为空并且路径是'/'首页 就跳转home主页
  if (isPath) proxy.$openFrame('/home')
  // 如果userid为空跳转登录页主页
  // 运用逻辑非 检查变量是null undefined 0 false NaN 或是空string
  if (!isUserid) proxy.$openFrame('/')
})

/**
 * 组件响应式改动
 */
onUpdated(() => {
  // 如果userid不为空，判断是否登录信息过期
  if (localStorage.getItem('userid')) isSignPeriod()
})

/**
 * 组件实例被卸载之后
 */
onUnmounted(() => { })

// 获取菜单栏展开收缩回调函数
function handleChang(val) {
  isCollapse.value = val
}

// 多个异步请求的场景
// async function queryData(id) {
// const info = await axios.get('/async1');
// const ret = await axios.get('async2?info=' + info.data);
// return ret;
// }
// queryData.then(ret=>{
// console.log(ret)
// })

// 登录信息缓存判断
function isSignPeriod() {
  let currentTime = new Date().getTime() // 当前时间
  let lastTime = localStorage.getItem('lastTime') // 之前时间
  let outTime = 7 * 24 * 60 * 60 * 1000 // 超时时间 一周 天 * 时 * 分 * 秒 * 毫秒
  // 超过了就返回是 未超过就是否
  if (currentTime - lastTime > outTime) {
    ElMessage.error('登录信息已过期，请重新登录')
    localStorage.clear()
    proxy.$openFrame('/')
  }
}

// 路由守卫监控 判断是否添加新的tab标签 修改数组
proxy.$router.beforeEach((to) => {
  if (to['name'] === '登录') {
    store.tabsData.tabsArrs = [{ name: '主页', path: '/home', query: {} }]
  } else {
    // 判断需要缓存的，添加进缓存
    if (to.meta.keepAlive) {
      // 判断未添加进去的，添加进去
      if (!store.includePaths.includes(to['path'])) { store.includePaths.push(to['path']) }
    }
    // 设置tab标签选中
    store.tabsData.activeTab = to['path']
    // 设置菜单选中
    store.menuActive = to['path']
    // 对象时为proxy对象，用toRaw()取值 或者用json序列化之后可获取值 JSON.parse(JSON.stringify(store.getters.menuList))
    // arrs.includes(to.path) 可用includes遍历数组中是否有某个元素
    let falg = true
    let newArrs = toRaw(store.tabsData.tabsArrs)
    // 判断跳转路径是否存在，不存在就往tab标签数组中添加
    newArrs.forEach((item) => {
      if (item['path'] === to['path']) {
        falg = false
      }
    })
    if (falg) {
      store.tabsData.tabsArrs.push({ name: to['name'], path: to['path'], query: to['query'] })
    }
  }
})
</script>

<style>
#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  color: var(--el-text-color-primary);
}

.layout-container {
  height: 100vh;
  background: var(--el-bg-color-page);
}

/* 子页面容器 */
.contianer {
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

/* 子页面标题 */
.page_title {
  font-size: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 6px solid var(--el-color-primary);
}

/* 子页面二级标题 */
.page_sub_title {
  margin-bottom: 20px;
}

/* 字体居右 */
.text_r {
  text-align: right;
}

.text_c {
  text-align: center;
}

.text_l {
  text-align: left;
}

/* 列表页面底部分页器和按钮区域 */
.table_bottom {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* 列表页面底部按钮区域 */
.table_bottom .function_btn {
  flex-grow: 1;
}

/* 列表页表格行内表现 */
.row_img {
  width: 50px;
  height: 50px;
}

.row_p {
  color: var(--el-text-color-secondary);
}

.row_p span {
  color: var(--el-text-color-primary);
}

.row_link {
  color: var(--el-color-primary);
  cursor: pointer;
}

.row_link:hover {
  text-decoration: underline;
}

/* 表单页面label的标注 */
.label_tips {
  color: var(--el-text-color-secondary);
}

/* 详情页面描述列表 label宽度 */
.info_descriptions .el-descriptions__label {
  width: 200px;
}

.flex {
  display: flex;
}

.flex_ac {
  align-items: center;
}

.flex_grow {
  flex-grow: 1;
}

.flex_shrink {
  flex-shrink: 0;
}

.btns_sticky {
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 3;
}

/* 单行字体超出省略号 */
.text_es {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text_es2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.mar_r {
  margin-right: 5px;
}

.mar_l {
  margin-left: 5px;
}

.mar_b {
  margin-bottom: 5px;
}

/* 编辑页面 p标签模仿 input */
.el_form_p {
  padding: 0 15px;
  background-image: none;
  transition: var(--el-transition-box-shadow);
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  color: var(--el-input-text-color, var(--el-text-color-regular));
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
}

.el_form_p.disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el_form_p.placeholder {
  color: var(--el-disabled-text-color);
}

/* 多个input */
.input_min {
  width: auto !important;
  margin-right: 5px;
}

.input_max {
  width: auto !important;
  flex-grow: 1;
  margin-right: 5px;
}
</style>
