<template>
  <el-tabs class="layout_tabs" v-model="tabIndex" type="card" closable @tab-remove="removeTab" @tab-click="changeTab">
    <el-tab-pane v-for="item in tableTabs" :key="item.path" :label="item.name" :name="item.path"></el-tab-pane>
  </el-tabs>
</template>

<script setup name='layout_tabs'>
/** 引入 */
import { ref, getCurrentInstance } from 'vue'
import { useStore } from '@/store/index'
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()
const store = useStore()
const tabIndex = ref(store.tabsData.activeTab)
const tableTabs = ref(store.tabsData.tabsArrs)

// 监测pinia中数值的变化
store.$subscribe(() => {
  tabIndex.value = store.tabsData.activeTab
  tableTabs.value = store.tabsData.tabsArrs
})

// tab标签切换
const changeTab = (targetVal) => {
  if (targetVal.active) {
    return false
  } else {
    // 设置标签选中
    // store.tabsData.activeTab = targetVal.props.name
    // 获取当前选中序列中的携带参数
    let querVal = store.tabsData.tabsArrs[targetVal['index']].query
    // 跳转页面
    proxy.$openFrame(targetVal.props.name, querVal)
  }
}

// tab标签删除
function removeTab(targetPath) {
  let tabs = store.tabsData.tabsArrs
  let activeTab = store.tabsData.activeTab
  let querVal = {}
  // 判断主页不能删除
  if (targetPath == '/home') {
    ElMessage.error('主页不能删除！')
    return false
  }

  // 遍历设置菜单选中项
  if (activeTab === targetPath) {
    tabs.forEach((tab, index) => {
      if (tab['path'] === targetPath) {
        const nextTab = tabs[index + 1] || tabs[index - 1]
        if (nextTab) {
          querVal = nextTab['query']
          activeTab = nextTab['path']
        }
      }
    })
  }

  store.tabsData.activeTab = activeTab
  store.tabsData.tabsArrs = tabs.filter((tab) => tab['path'] !== targetPath)
  store.includePaths = store.includePaths.filter(item => item !== targetPath)
  proxy.$openFrame(activeTab, querVal)
}
</script>
<style>
.layout_tabs .el-tabs__item.is-active {
  background: #fff;
}

.layout_tabs .el-tabs__header {
  margin: 0;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.12);
  z-index: 99;
}
</style>
