<template>
  <div class="top">
    <el-button class="fold" type="primary" :icon="collapse ? 'Expand' : 'Fold'" title="折叠面板"
      @click="CollapseToggle(collapse)"></el-button>
    <el-header>
      <div class="capsule">
        <el-button-group>
          <el-button icon="ArrowLeft" title="返回" type="info" text @click="$router.back()"></el-button>
          <el-button icon="ArrowRight" title="前进" type="info" text @click="$router.forward()"></el-button>
          <el-button icon="Refresh" title="刷新" type="info" text @click="reloadapp()"></el-button>
        </el-button-group>
        <el-breadcrumb class="mar_l" separator-icon="ArrowRight">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, index) in crumbtxt" :key="index">{{ item }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="capsule">
        <!-- <el-badge class="bell_badge" :value="bellNumber" :max="99" :hidden="bellNumber == 0 ? true : false">
          <el-button type="info" text icon="Bell"></el-button>
        </el-badge> -->
        <img class="avatar" src="@/assets/img/no_avatar.png" />
        <el-dropdown @visible-change="alinb">
          <el-button class="user_btn" link>
            {{ JsonData['PersonName'] }}
            &nbsp;
            <el-icon class="transition_icon" :style="{ transform: 'rotateZ(' + dropdownIcon + 'deg)' }">
              <ArrowUpBold />
            </el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="$openFrame('/user/info')">
                <el-icon>
                  <Document />
                </el-icon>
                基本信息
              </el-dropdown-item>
              <el-dropdown-item @click="$openFrame('/user/password')">
                <el-icon>
                  <Unlock />
                </el-icon>
                修改密码
              </el-dropdown-item>
              <el-dropdown-item @click="outsys">
                <el-icon>
                  <SwitchButton />
                </el-icon>
                安全退出
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </div>
    </el-header>
  </div>
</template>

<script setup name='layout_top'>
/** 引入 */
import { onMounted, ref, inject, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from '@/store/index'

const { proxy } = getCurrentInstance()
// 刷新当前页面
const reloadapp = inject(['reload'])

const store = useStore()
// const { proxy } = getCurrentInstance()
// const breadcrumbArrs = ref([])

defineProps({
  collapse: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['handlechg'])
const crumbtxt = ref(store.breadCrumbText)

// 监测pinia中数值的变化
store.$subscribe(() => {
  crumbtxt.value = store.breadCrumbText
})

// const bellNumber = 322
const JsonData = ref({
  Img: '',
  PersonName: '未获取到'
})
const dropdownIcon = ref('0')

// 修改菜单收缩展开状态
function CollapseToggle(val) {
  emit('handlechg', !val)
}

// 修改图标
function alinb(val) {
  dropdownIcon.value = val ? '180' : '0'
}

// 退出系统
function outsys() {
  // 删除userid
  // localStorage.removeItem('userid')
  // 清除所有缓存
  localStorage.clear()

  proxy.$openFrame('/')
}

onMounted(() => {
  loadData()
})

function loadData() {
  const id = store['userid'] || 0
  if (id == 0) {
    return false
  }

  let url = '/Ashx/WebAPI.ashx?type=getAccount'
  proxy.$post(url, { ID: id }).then((response) => {
    let isarray = Array.isArray(response) && response.length > 0
    if (isarray) {
      JsonData.value = response[0]
    } else {
      JsonData.value = { Img: '', PersonName: '未获取到' }
      ElMessage.error('未获取到用户资料')
    }
  })
}
</script>
<style scoped>
.top {
  display: flex;
  align-items: center;
  padding: 10px 0 10px;
}

.fold {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 20px;
  padding: 0 5px;
  height: 40px;
}

.el-header {
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--el-bg-color);
  border-radius: var(--el-border-radius-base);
}

.capsule {
  display: flex;
  align-items: center;
}

.capsule .el-button {
  font-size: 20px;
}

.mar_l {
  margin-left: 10px;
}

.bell_badge {
  margin-right: 50px;
}

.user_btn {
  margin-left: 6px;
  font-size: 16px !important;
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 42px;
}

.transition_icon {
  transition: all 0.5s;
}
</style>
