<template>
  <div class="layout_aside">
    <div class="aside_logo" :style="{ width: collapse ? '64px' : '200px' }">
      <img class="logo" v-if="collapse" alt="logo" src="@/assets/img/logo4.png">
      <img class="logo" v-else alt="logo" src="@/assets/img/logo3.png">
    </div>

    <el-scrollbar>
      <el-menu class="aside_menu" :default-active="navActive" :collapse="collapse" active-text-color="#ffd04b"
        background-color="#545c64" text-color="#fff" router unique-opened>
        <template v-for="(item) in JsonData" :key="item['name']">
          <el-sub-menu v-if="(item['chs'] != null && item['chs'].length >= 1)" :index="item['path']">
            <template #title>
              <el-icon>
                <component :is="item['icon']" />
              </el-icon>
              <span>{{ item['name'] }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-for="(subitem) in item['chs']" :key="subitem['name']" :index="subitem['path']">
                <span>{{ subitem['name'] }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>

          <el-menu-item v-else :index="item['path']">
            <el-icon>
              <component :is="item['icon']" />
            </el-icon>
            <template #title>{{ item['name'] }}</template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>

    <div class="copyright" v-if="collapse">resee</div>
    <div class="copyright" v-else>©{{ currentDate }} resee software.</div>
  </div>
</template>
<script setup name='layout_left'>
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useStore } from '@/store/index'
import { ElMessage } from 'element-plus'
const { proxy } = getCurrentInstance()
const store = useStore()
const currentDate = ref(proxy.$getCurrentDate('YY'))

defineProps({
  collapse: {
    type: Boolean,
    default: false
  }
})

const navActive = ref(store.menuActive)

const JsonData = ref([
  {
    icon: 'House',
    name: '主页',
    path: '/home'
  }
])

// 监测pinia中数值的变化
store.$subscribe(() => {
  navActive.value = store.menuActive
})

onMounted(() => {
  loadData()
})

function loadData() {
  proxy.$post('/Ashx/WebAPI.ashx?type=getAccountPower', { accountid: store['userid'] }).then((response) => {
    let isarray = Array.isArray(response) && response.length > 0
    isarray ? (JsonData.value = response) : ElMessage.error('获取菜单失败，请重试')
    // JsonData.value = [
    //   { "icon": "House", "name": "主页", "path": "/home" },
    //   {
    //     "icon": "User", "name": "用户管理", "path": "/user",
    //     "chs": [
    //       { "name": "微信用户", "path": "/user/weixinyonghu" },
    //       { "name": "司机", "path": "/user/siji" },
    //       { "name": "业务员", "path": "/user/yewuyuan" },
    //       { "name": "分拣员", "path": "/user/fenjianyuan" },
    //       { "name": "财务人员", "path": "/user/caiwu" },
    //       { "name": "客户", "path": "/user/kehu" }
    //     ]
    //   },
    //   {
    //     "icon": "MessageBox", "name": "库存管理", "path": "/inventory",
    //     "chs": [
    //       { "name": "库存列表", "path": "/inventory/default" },
    //       { "name": "入库管理", "path": "/inventory/ruku" },
    //       { "name": "出库管理", "path": "/inventory/chuku" }
    //     ]
    //   },
    //   {
    //     "icon": "Goods", "name": "商品管理", "path": "/goods",
    //     "chs": [
    //       { "name": "商品列表", "path": "/goods/default" },
    //       { "name": "商品分类", "path": "/goods/fenlei" }
    //     ]
    //   },
    //   {
    //     "icon": "Tickets", "name": "订单管理", "path": "/order",
    //     "chs": [
    //       { "name": "订单列表", "path": "/order/default" },
    //       { "name": "订单汇总", "path": "/order/huizong" },
    //       { "name": "订单分拣", "path": "/order/fenjian" },
    //       { "name": "采购单", "path": "/order/caigou" }
    //     ]
    //   },
    //   {
    //     "icon": "PieChart", "name": "财务管理", "path": "/finance",
    //     "chs": [
    //       { "name": "退款列表", "path": "/finance/tuikuan" },
    //       { "name": "客户结算", "path": "/finance/jiesuan" }]
    //   },
    //   {
    //     "icon": "OfficeBuilding", "name": "商城管理", "path": "/mall",
    //     "chs": [
    //       { "name": "广告轮播", "path": "/mall/banner" },
    //       { "name": "优惠券", "path": "/mall/coupon" },
    //       { "name": "每日特惠", "path": "/mall/promotion" },
    //       { "name": "限时抢购", "path": "/mall/purchase" }
    //     ]
    //   },
    //   {
    //     "icon": "Operation", "name": "参数设置", "path": "/option",
    //     "chs": [
    //       { "name": "线路管理", "path": "/option/xianlu" }
    //     ]
    //   },
    //   {
    //     "icon": "Setting", "name": "系统设置", "path": "/set",
    //     "chs": [
    //       { "name": "权限管理", "path": "/set/quanxian" }
    //     ]
    //   }
    // ]
  })
}
</script>
<style scoped>
.layout_aside {
  display: flex;
  flex-direction: column;
  border-right: solid 1px var(--el-menu-border-color);
  background: #545c64;
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
}

.aside_logo {
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: 80px;
  background: #fff;
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  overflow: hidden;
}

.aside_logo .logo {
  width: auto;
  height: 50px;
  object-fit: contain;
}

.el-menu {
  border-right: none;
}

.el-menu-item-group .el-menu-item {
  color: #94aab5;
  background-color: #394148;
}

.el-menu-item-group .el-menu-item.is-active {
  color: var(--el-menu-active-color);
}

.el-menu-item-group .el-menu-item:hover {
  background-color: #2d373f;
}

.copyright {
  margin: 0 10px;
  border-top: 1px dashed #454e58;
  font-size: 14px;
  color: #262d34;
  padding: 5px 0;
  text-align: center;
}
</style>
