<template>
  <el-image :src="imgVal" fit="cover" :preview-src-list="[imgVal]" hide-on-click-modal preview-teleported>
    <template #error>
      <img class="image-slot" src="@/assets/img/no_img.png" />
    </template>
  </el-image>
</template>

<script setup name='wc_img'>
import { computed } from 'vue'
const props = defineProps({
  src: {
    type: String,
    default: ''
  }
})

const imgVal = computed(() => {
  let props_src = props.src
  if (props_src === '' || props_src == undefined || props_src == null) {
    return ''
  } else if (props_src.includes('http://') || props_src.includes('https://') || props_src.includes('data:image/')) {
    return props_src
  } else {
    return 'https://rssyh.img-cn-hangzhou.aliyuncs.com/' + props_src
  }
})

// watch(
//   () => props.src,
//   (newValue) => {
//     if (newValue === '') {
//       imgVal.value = ''
//     } else if (newValue.includes('http://') || newValue.includes('https://') || newValue.includes('data:image/')) {
//       imgVal.value = newValue
//     } else {
//       imgVal.value = 'http://scxs2021.img-cn-hangzhou.aliyuncs.com/' + newValue
//     }
//   },
//   { immediate: true }
// )
</script>
<style scoped>
.image-slot {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
