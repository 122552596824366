<template>
  <i class="copytext" @click="copyText" title="点击复制文本">{{ val }}</i>
</template>

<script setup name='copy_text'>
import { ElMessage } from 'element-plus'
const props = defineProps({
  val: {
    type: [String, Number],
    default: ''
  }
})

function copyText() {
  const input = document.createElement('input')
  input.setAttribute('readonly', 'readonly')
  input.setAttribute('value', props.val)
  document.body.appendChild(input)
  input.setSelectionRange(0, 9999)
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
  ElMessage.success('复制成功!')
}
</script>
<style scoped>
.copytext {
  font-style: inherit;
}

.copytext:hover {
  opacity: 0.5;
  cursor: pointer;
}
</style>
