import { createRouter, createWebHistory } from "vue-router"
//createWebHashHistory 哈希模式 
//createWebHistory 历史模式

// 3. 创建路由实例并传递 `routes` 配置
export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: "登录",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/login/login.vue')
        },
        {
            path: '/home',
            name: "主页",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/home/home.vue')
        },
        // 本用户管理
        {
            path: '/user/info',
            name: "基本信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/that/info.vue')
        },
        {
            path: '/user/password',
            name: "修改密码",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/that/password.vue')
        },
        // 用户管理-微信用户
        {
            path: '/user/weixinyonghu',
            name: "微信用户列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/weixinyonghu.vue')
        },
        {
            path: '/user/weixinyonghu/info',
            name: "微信用户详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/weixinyonghu/info.vue')
        },
        // 用户管理-司机
        {
            path: '/user/siji',
            name: "司机列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/siji.vue')
        },
        {
            path: '/user/siji/info',
            name: "司机详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/siji/info.vue')
        },
        {
            path: '/user/siji/edit',
            name: "编辑司机信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/siji/edit.vue')
        },
        // 用户管理-员工列表
        {
            path: '/user/yuangong',
            name: "员工列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/yuangong.vue')
        },
        {
            path: '/user/yuangong/info',
            name: "员工详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/yuangong/info.vue')
        },
        {
            path: '/user/yuangong/edit',
            name: "编辑员工信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/yuangong/edit.vue')
        },
        // 用户管理-业务员
        {
            path: '/user/yewuyuan',
            name: "业务员列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/yewuyuan.vue')
        },
        {
            path: '/user/yewuyuan/info',
            name: "业务员详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/yewuyuan/info.vue')
        },
        {
            path: '/user/yewuyuan/edit',
            name: "编辑业务员信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/yewuyuan/edit.vue')
        },
        // 用户管理-供应商
        {
            path: '/user/gongyingshang',
            name: "供应商列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/gongyingshang.vue')
        },
        {
            path: '/user/gongyingshang/info',
            name: "供应商详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/gongyingshang/info.vue')
        },
        {
            path: '/user/gongyingshang/edit',
            name: "编辑供应商信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/gongyingshang/edit.vue')
        },
        // 用户管理-分拣员
        {
            path: '/user/fenjianyuan',
            name: "分拣员列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/fenjianyuan.vue')
        },
        {
            path: '/user/fenjianyuan/info',
            name: "分拣员详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/fenjianyuan/info.vue')
        },
        {
            path: '/user/fenjianyuan/edit',
            name: "编辑分拣员信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/fenjianyuan/edit.vue')
        },
        // 用户管理-采购员
        {
            path: '/user/caigouyuan',
            name: "采购员列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/caigouyuan.vue')
        },
        {
            path: '/user/caigouyuan/info',
            name: "采购员详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/caigouyuan/info.vue')
        },
        {
            path: '/user/caigouyuan/edit',
            name: "编辑采购员信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/caigouyuan/edit.vue')
        },
        // 用户管理-客户
        {
            path: '/user/kehu',
            name: "客户列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/user/kehu.vue')
        },
        {
            path: '/user/kehu/info',
            name: "客户详细信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/kehu/info.vue')
        },
        {
            path: '/user/kehu/edit',
            name: "编辑客户信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/user/kehu/edit.vue')
        },
        //  商城管理
        {
            path: '/mall/banner',
            name: "广告轮播",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/mall/banner.vue')
        },
        {
            path: '/mall/banner/info',
            name: "广告详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/banner/info.vue')
        },
        {
            path: '/mall/banner/edit',
            name: "编辑广告信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/banner/edit.vue')
        },
        {
            path: '/mall/coupon',
            name: "优惠券列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/mall/coupon.vue')
        },
        {
            path: '/mall/coupon/info',
            name: "优惠券详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/coupon/info.vue')
        },
        {
            path: '/mall/coupon/edit',
            name: "编辑优惠券信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/coupon/edit.vue')
        },
        {
            path: '/mall/promotion',
            name: "每日特惠",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/promotion.vue')
        },
        {
            path: '/mall/purchase',
            name: "限时抢购",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/purchase.vue')
        },
        {
            path: '/mall/purchase/info',
            name: "限时抢购详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/purchase/info.vue')
        },
        {
            path: '/mall/purchase/edit',
            name: "编辑限时抢购信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/mall/purchase/edit.vue')
        },
        // 商品管理
        {
            path: '/goods/default',
            name: "商品列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/goods/default.vue')
        },
        {
            path: '/goods/info',
            name: "商品详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/goods/goods/info.vue')
        },
        {
            path: '/goods/edit',
            name: "编辑商品信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/goods/goods/edit.vue')
        },
        {
            path: '/goods/fenlei',
            name: "商品分类",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/goods/fenlei.vue')
        },
        {
            path: '/goods/aiprice',
            name: "智能定价",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/goods/aiprice.vue')
        },
        {
            path: '/goods/jifengoods',
            name: "积分商品",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/goods/jifengoods.vue')
        },
        {
            path: '/goods/jifengoods/info',
            name: "积分商品详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/goods/jifengoods/info.vue')
        },
        {
            path: '/goods/jifengoods/edit',
            name: "编辑积分商品信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/goods/jifengoods/edit.vue')
        },
        // 订单管理
        {
            path: '/order/default',
            name: "订单列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/default.vue')
        },
        {
            path: '/order/info',
            name: "订单详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/order/info.vue')
        },
        {
            path: '/order/edit',
            name: "编辑线下订单信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/order/edit.vue')
        },
        {
            path: '/order/jifenorder',
            name: "积分订单",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/jifenorder.vue')
        },
        {
            path: '/order/jifenorder/info',
            name: "积分订单详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/jifenorder/info.vue')
        },
        {
            path: '/order/add',
            name: "添加线下订单",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/order/add.vue')
        },
        {
            path: '/order/fenjian',
            name: "商品分拣",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/fenjian.vue')
        },
        {
            path: '/order/huizong',
            name: "订单汇总",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/huizong.vue')
        },
        {
            path: '/order/tuihuo',
            name: "订单退货",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/tuihuo.vue')
        },
        {
            path: '/order/tuihuo/info',
            name: "订单退货详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/tuihuo/info.vue')
        },
        {
            path: '/order/tuihuo/edit',
            name: "编辑订单退货信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/tuihuo/edit.vue')
        },
        {
            path: '/order/pindanorder',
            name: "拼单列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/order/pindanorder.vue')
        },
        {
            path: '/order/pindanorder/info',
            name: "拼单详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/order/pindanorder/info.vue')
        },
        // 采购管理
        {
            path: '/caigou/caigou',
            name: "采购单",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/caigou/caigou.vue')
        },
        {
            path: '/caigou/info',
            name: "采购单详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/caigou/caigou/info.vue')
        },
        {
            path: '/caigou/edit',
            name: "编辑采购单信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/caigou/caigou/edit.vue')
        },
        {
            path: '/caigou/gettake',
            name: "采购单收货",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/caigou/caigou/gettake.vue')
        },
        {
            path: '/caigou/tuihuo',
            name: "采购退货",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/caigou/tuihuo.vue')
        },
        {
            path: '/caigou/tuihuo/info',
            name: "采购退货详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/caigou/tuihuo/info.vue')
        },
        {
            path: '/caigou/tuihuo/edit',
            name: "编辑采购退货信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/caigou/tuihuo/edit.vue')
        },
        // 库存管理
        {
            path: '/inventory/default',
            name: "现有库存",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/default.vue')
        },
        {
            path: '/inventory/ruku',
            name: "入库列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/ruku.vue')
        },
        {
            path: '/inventory/ruku/edit',
            name: "编辑入库信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/ruku/edit.vue')
        },
        {
            path: '/inventory/chuku',
            name: "出库列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/chuku.vue')
        },
        {
            path: '/inventory/chuku/edit',
            name: "编辑出库信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/chuku/edit.vue')
        },
        {
            path: '/inventory/baosun',
            name: "报损列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/baosun.vue')
        },
        {
            path: '/inventory/baosun/edit',
            name: "添加报损信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/baosun/edit.vue')
        },
        {
            path: '/inventory/baoyi',
            name: "报溢列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/baoyi.vue')
        },
        {
            path: '/inventory/baoyi/edit',
            name: "添加报溢信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/baoyi/edit.vue')
        },
        {
            path: '/inventory/pandian',
            name: "库存盘点",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/inventory/pandian.vue')
        },
        {
            path: '/inventory/pandian/edit',
            name: "编辑库存盘点信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/pandian/edit.vue')
        },
        {
            path: '/inventory/pandian/info',
            name: "库存盘点信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/inventory/pandian/info.vue')
        },
        // 参数设置
        {
            path: '/option/usertype',
            name: "客户类型",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/option/usertype.vue')
        },
        {
            path: '/option/usertype/info',
            name: "客户类型详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/option/usertype/info.vue')
        },
        {
            path: '/option/usertype/edit',
            name: "编辑客户类型信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/option/usertype/edit.vue')
        },
        {
            path: '/option/xianlu',
            name: "线路列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/option/xianlu.vue')
        },
        {
            path: '/option/xianlu/info',
            name: "线路详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/option/xianlu/info.vue')
        },
        {
            path: '/option/xianlu/edit',
            name: "编辑线路信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/option/xianlu/edit.vue')
        },
        {
            path: '/option/jifen',
            name: "积分设置",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/option/jifen.vue')
        },
        // 财务管理
        {
            path: '/finance/tuikuan',
            name: "退款列表",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/finance/tuikuan.vue')
        },
        {
            path: '/finance/tuikuan/info',
            name: "退款详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/tuikuan/info.vue')
        },
        {
            path: '/finance/jiesuan',
            name: "客户结算",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/finance/jiesuan.vue')
        },
        {
            path: '/finance/kucunchengben',
            name: "库存成本",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/kucunchengben.vue')
        },
        {
            path: '/finance/jiesuan/info',
            name: "客户结算详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/jiesuan/info.vue')
        },
        {
            path: '/finance/jiesuan/edit',
            name: "编辑客户结算信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/jiesuan/edit.vue')
        },
        {
            path: '/finance/huizong',
            name: "应收账款",
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/finance/huizong.vue')
        },
        {
            path: '/finance/fenjianyuanjixiao',
            name: "分拣员绩效",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/fenjianyuanjixiao.vue')
        },
        {
            path: '/finance/yewuyuanjixiao',
            name: "业务员绩效",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/yewuyuanjixiao.vue')
        },
        {
            path: '/finance/sijijixiao',
            name: "司机绩效",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/finance/sijijixiao.vue')
        },
        // 报表管理
        {
            path: '/baobiao/shangpinxiaoliang',
            name: "商品销量",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/baobiao/shangpinxiaoliang.vue')
        },
        {
            path: '/baobiao/kehutongji',
            name: "客户统计",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/baobiao/kehutongji.vue')
        },
        {
            path: '/baobiao/liruntongji',
            name: "利润统计",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/baobiao/liruntongji.vue')
        },
        {
            path: '/baobiao/yingyeshuju',
            name: "营业数据",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/baobiao/yingyeshuju.vue')
        },
        {
            path: '/baobiao/tuikuantuidan',
            name: "退款退单",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/baobiao/tuikuantuidan.vue')
        },
        // 系统设置
        {
            path: '/set/quanxian',
            name: "权限管理",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/set/quanxian.vue')
        },
        {
            path: '/set/quanxian/info',
            name: "权限详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/set/quanxian/info.vue')
        },
        {
            path: '/set/quanxian/edit',
            name: "编辑权限信息",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/set/quanxian/edit.vue')
        },
        // 404页面
        {
            path: '/404',
            name: "404",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/error/404.vue')
        },
        { path: "/:pathMatch(.*)", redirect: "/404" }
    ]
})

export default router